<template>
	<div class="tch-main">
		<el-card class="marT20" >
			<div class='tch-top'>
				<span class="jp-title">机票改签</span>			
			</div>
			<!-- 要改签的人 -->
			<div class="tch-fl-main"  v-for="reItem in tkChangePersonList" :key="reItem.psgId">
				<div class="tch-fl1">
					<el-checkbox
								:disabled="isCanCheck(reItem,chooseRange)"
								:checked="!!choosePsg[reItem.psgId]"
								@change="getCheckStatus(reItem)"></el-checkbox>
				</div>
				<div class="tch-fl2">
					<span>{{reItem.psgName}}</span>
					<span class="padL10">{{'('+ reItem.idNo +')'}}</span>
				</div>
				<div class="tch-fl3">票号：{{reItem.tkno}}</div>
				<div class="tch-fl4">
					<span v-if="reItem.psgType==1">成人</span>
					<span v-if="reItem.psgType==2">儿童</span>
					<span v-if="reItem.psgType==3">婴儿</span>
				</div>
				<div class="tch-fl5">票价：¥{{reItem.salePrice}}</div>
				<div class="tch-fl6">基建/燃油：¥{{reItem.airportFee + reItem.tax}}</div>
				<div class="tch-fl7">服务费：¥{{reItem.serviceFee}}</div>
			</div>
			<!-- 要改签的航段 -->
			
			<div  class="tch-con">
				<div v-for="(rangeItem, rangeIndex) in tkChangeInfoList" :key="rangeIndex" :class="{marT30:rangeIndex>0}">
					<div  class="tch-wf">
						<span >第{{rangeIndex+1}}程: {{rangeItem.departCityName+' - '+rangeItem.arriveCityName}}</span>
					</div>
					
					<div class="tch-con-t">
						<div class="tch-l0">
							<el-checkbox
										:disabled="isCanChooseRange(rangeItem,choosePsg)"
										:checked="isChooseRange(rangeItem.sn,chooseRange)"
										@change="getRangeCheckStatus(rangeItem,rangeIndex)"></el-checkbox>
						</div>
						<div class="tch-l1 ">
							<div>{{rangeItem.airlineName}}</div>
							<div>{{rangeItem.flightNo}}</div>
							
						</div>
						<div class="tch-l2" >
							<div class='tch-l2-hbt'>
								<div class="tch-l2-hbl">{{rangeItem.departTime}}</div>
								<div class="tch-l2-hbc">{{rangeItem.flightTime}}</div>
								<div class="tch-l2-hbr">{{rangeItem.arriveTime}}</div> 
							</div>
							<div class='tch-l2-hbf'>
								<div class="tch-l2-hbfl">{{rangeItem.departAirportName}}{{rangeItem.departTerminal}}</div> 
								<div class="tch-l2-hbfc"></div>
								<div class="tch-l2-hbfr">{{rangeItem.arriveAirportName}}{{rangeItem.arriveTerminal}}</div>
							</div> 
						</div>
						<div class="tch-l3">
							<div>{{rangeItem.departDate}}</div>
							<div class="tch-l3-pad">{{rangeItem.week}}</div>
						</div>
						<div class="tch-l4">
							<div>{{rangeItem.cabin}}/{{rangeItem.cabinName}}</div>
						</div>
						<div class="tch-l5">
							<el-button type="text" @click="visible=true">退改签规则</el-button>
						</div>
					</div>
					
					<div v-if="isChooseRange(rangeItem.sn,chooseRange)">
						<div class="searchDate">
							<el-date-picker
								type="date"
								v-model="rangeItem.newDepDate"
								value-format="yyyy-MM-dd"
								:picker-options="pickerOptions(minDay,maxDay)"
								class="dateBox"
								placeholder="选择日期">  
							</el-date-picker>
							<el-button type="primary"  class="queryBtn"  @click="queryTk(rangeIndex,rangeItem)">查询航班</el-button>
						</div>
					</div>
					<!--新的航程 -->
					<div v-if="chooseRange[rangeItem.sn] && rangeItem.chooseFlight && rangeItem.chooseFlight.flightNo ">
						<div class="tch-con-t" style="margin-top: 10px;" >
							<div class="tch-l0">
							</div>
							<div class="tch-l1">
								<div>{{rangeItem.chooseFlight.airlineName}}</div>
								<div>{{rangeItem.chooseFlight.flightNo}}</div>
							</div>
							<div class="tch-l2" >
								<div class='tch-l2-hbt'>
									<div class="tch-l2-hbl">{{rangeItem.chooseFlight.departTime}}</div>
									<div class="tch-l2-hbc">{{rangeItem.chooseFlight.flyTimeStr}}</div>
									<div class="tch-l2-hbr">{{rangeItem.chooseFlight.arriveTime}}</div> 
								</div>
								<div class='tch-l2-hbf'>
									<div class="tch-l2-hbfl">{{rangeItem.chooseFlight.departAirportName}}{{rangeItem.chooseFlight.departTerminal}}</div> 
									<div class="tch-l2-hbfc"></div>
									<div class="tch-l2-hbfr">{{rangeItem.chooseFlight.arriveAirportName}}{{rangeItem.chooseFlight.arriveTerminal}}</div>
								</div> 
							</div>
							<div class="tch-l3">
								<div>{{rangeItem.chooseFlight.departDate}}</div>
								<div class="tch-l3-pad">{{rangeItem.chooseFlight.departDate | filterWeekformat}}</div>
							</div>
							<div class="tch-l4">
								<div>{{rangeItem.chooseFlight.chooseCabin.cabin}}/{{rangeItem.chooseFlight.chooseCabin.cabinName}}</div>
							</div>
							<div class="tch-l5">
								<el-button type="text" @click="visible=true">退改签规则</el-button>
							</div>
						</div>
						
						<!-- 违背原因 -->
						<div style="text-align: left;" v-show="rangeItem.chooseFlight.chooseCabin.violateItem">
							<div>违背事项：{{rangeItem.chooseFlight.chooseCabin.violateItem}}</div>
							<div>违背原因：{{rangeItem.chooseFlight.chooseCabin.reasonDesc}}</div>
						</div>
					</div>
					
					
				
					
					
					<el-dialog
						title="退改签规则"
						:visible.sync="visible"
						>
						<div class="dmain">
							<div class="dia-gz">
								<div class="gz-l">退票规则</div>
								<div class="gz-r">{{rangeItem.refundRule}}</div>
							</div> 
							<div class="dia-gz">
								<div class="gz-l">改签规则</div>
								<div class="gz-r">{{rangeItem.changeRule}}</div>
							</div> 
							<div class="dia-gz">
								<div class="gz-l">升舱规则</div>
								<div class="gz-r">{{rangeItem.upgradeRule}}</div>
							</div> 
						</div>
						<span slot="footer" class="dialog-footer">
						  <el-button @click="visible = false">取 消</el-button>
						  <el-button type="primary" @click="visible = false">确 定</el-button>
						</span>
					</el-dialog>
				</div>
				
				
			</div>
			
			
			
		</el-card> 
		<el-card style="margin-top: 20px;" v-if="needApprove">
			<!-- 审批规则 -->
			<div class="setSelect approval-rule" >
				<h1 slot="header" class="text-left approval-rule-title">审批规则</h1>
				<el-select
					v-model="chooseSpgz.gzmc" 
					:loading="loadingSpgzList"
					value-key
					automatic-dropdown
					@focus="getSpgzList()"
					@change="getCurrentSpgz" 
					:placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'"
					class="bfb-w100"
					>
				    <el-option
				      v-for="item in spgzList"
				      :key="item.gzId"
				      :label="item.gzmc"
				      :value="item">
				    </el-option>
				</el-select> 
			</div>
		</el-card> 
		<el-card style="margin-top: 20px;" v-show="allData.ccsqdId">
			<!-- 出差申请单 -->
			<div class="setSelect approval-rule" >
				<h1 slot="header" class="text-left approval-rule-title">出差申请单</h1>
				<el-input
					class="font15 width465 borderStyle" 
					placeholder="选择出差单号" 
					v-model="ccsqdDetail.ccsqdNo"
					:readonly="true"
					@focus="chooseCcsqd()"
					></el-input>
			</div>
		</el-card> 
		<!-- 违背原因选择 -->
		<yj-violation 
			:vltShow="isShowWeiBei" 
			:vltReason="violateItem" 
			byNumber="10901" 
			@chooseReason="chooseReason"
			@cancelReason="cancleReason"
			></yj-violation>
		<!-- 选择出差申请单 ChooseCcsqdList -->
		<yj-choose-ccsqd-list
		    v-if="isShowChooseCcsqd"
			:visible="isShowChooseCcsqd"
			proType="10901"
			:ccsqdNoOrId="allData.ccsqdId" 
			:routeId="allData.routeId"
			@cancel="isShowChooseCcsqd=false"
			@confirm="chooseTravelApply" >
		</yj-choose-ccsqd-list>
		
		<!-- 机票航班搜索弹窗 -->
		<div class="tch-search" v-if="searchVisible">
			<div class="search-main">
				<div class="searchTitle">
					<span class="font18">航班查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<div class="searchTop">
					<div class="searchCon">
						<span class="label">出发城市:</span>
						<el-input v-model="currentTkInfo.departCityName"></el-input>
					</div>
					<div class="iconRank iconfont yj-icon-jh_aircraft">
					</div>
					<div class="searchCon">
						<span class="label">到达城市:</span>
						<el-input v-model="currentTkInfo.arriveCityName"></el-input>	
					</div>
					<el-date-picker
						type="date"
						v-model="currentTkInfo.newDepDate"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions(minDay,maxDay)"
						class="searchDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="button" :loading="loadingFlightList==1" @click="searchTk">
						<span v-show="loadingFlightList != 1">搜索</span>
						<span v-show="loadingFlightList == 1">搜索航班中...</span>
					</el-button>
				</div>
				<div class="list-main">
					<flight-list
					    v-if="flightList.length>0"
						:flightList="flightList"
						:flightMap="flightMap" 
						:priceMap="flightMap.priceMap"
						:planeMap="flightMap.planeMap" 
						@chooseFlight="toChooseFlight">
					</flight-list>
					
					<el-empty :image-size="200" v-show="loadingFlightList == 3 "></el-empty>
																						
					<flight-list-loading :loading="loadingFlightList == 1"></flight-list-loading>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import {
mapGetters,
mapState
} from 'vuex';

	// 机票模块公共js文件
	import tkCommonJs from './../../tkCommon/tkCommon.js';
import FlightList from './../tkQuery/FlightList.vue';
import FlightListLoading from './../tkQuery/FlightListLoading.vue';
	export default{
		name: 'TkRefundPerson',
		components:{
			'flight-list':FlightList,
			'flight-list-loading':FlightListLoading,
		},
		data() {
			return {
				tkChangePersonList:[], // 机票改签人列表
				tkChangeInfoList:[], // 机票改签航班信息
				tripType:1,
				
				visible: false, // 退改签规则显示
				psgIdList:[], //乘机人id列表
				rangeIdList:[], //航段id列表
				searchVisible: false, // 查询航班显示
				
				loadingFlightList: 0, // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				choosePsg:{},   // 当前选中的乘机人
				chooseRange:{}, // 当前选中的航段
				minEmpMap:{}, // 当前的最小职级
				// 当前要改签的航班或者航段
				currentTkInfo: {}, // 机票航班信息
				currentRangIndex:0,//机票当前查询航段的索引
				chooseFlight:{}, // 当前改签选中的航班
				// 航班列表
				flightList:[],
				flightMap:{},
				cacheId:"",

				// 违背原因
				isShowWeiBei:false,
				violateItem:'',
				// 审批规则模块
				needApprove:false, // 是否需要审批
				spgzList: [], // 审批规则列表
				isWuXuShenPi:false,// 默认无需审批
				loadingSpgzList:false, // 加载审批规则列表
				// 选中的审批规则
				chooseSpgz:{
					gzId:'', // 审批规则id
					gzmc:'', // 审批规则名称
					gzdm:''  // 审批规则代码
				},
				ccsqdDetail:{}, // 出差单的详情
				
				
				isShowChooseCcsqd:false, // 是否显示出差申请单弹窗

				isShowWei:false, // 是否违背
			}
		},
		props:{
			allData:{
				type: Object,
				default(){
					return {}
				}
			}
		},
		
		computed:{
			...mapState({
				// flightList: state => state.tk.flightList,	
			}),
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 1正常单改签 ， 2改签单改签
			changeFlag(){
				return this.$route.query.changeFlag;
			},
			// 员工信息
			userInfo(){
				return this.$common.getUserInfo();
			},


			//  var sqdGk = this.$common.getVipParams('20024', 0);
        // if (data.ccsqdId && sqdGk == 1) {
			 // 申请单管控 sqdGk  //改签日期是否受出差单管控,1管控,0不管控,默认为0
		   sqdGk(){ 
			   var sqdGk = this.getVipParamterByParNo('20024', 0);
			   return sqdGk
		   },
		   
		   // 最小值
		   minDay(){ 
			   var minDate= this.$common.getAppointDay(new Date(),0);
			   return this.minMaxDayRange.minDate || minDate
		   },
		   // 最大值
		   maxDay(){
			   var maxDate = this.$common.getAppointDay(new Date(),365);
			   return this.minMaxDayRange.maxDate || maxDate
		   },
		   minMaxDayRange(){
			   var minDate= this.$common.getAppointDay(new Date(),0);
			   var maxDate = this.$common.getAppointDay(new Date(),365);
			   
			   if(this.sqdGk == 1 && this.tripType == 1 && this.ccsqdDetail){
				   // 有行程id，取行程中的日期范围
				   	if(this.ccsqdDetail.chooseRouteItem){
							let routeStart = this.ccsqdDetail.chooseRouteItem.routeStart;
							let routeEnd = this.ccsqdDetail.chooseRouteItem.routeEnd;
							minDate = routeStart<minDate ?minDate :routeStart;
							maxDate = routeEnd > maxDate ?maxDate :routeEnd;
					}else{// 无行程   取 出差申请单中的日期范围
						let dateBegin = this.ccsqdDetail.dateBegin;
						let dateEnd = this.ccsqdDetail.dateEnd;
						minDate = dateBegin<minDate ?minDate :dateBegin;
						maxDate = dateEnd > maxDate ?maxDate :dateEnd;
					}		   
				   
			   }
			 return {
				 minDate,
				 maxDate
			 }
			   
		   },
		   
			// 计算可显示航班数量
			filterFlightList(){
				let newFlightList = [];
				newFlightList = this.flightList.filter((flightItem)=>{
					return !flightItem['isNoShow']
				})
				return newFlightList
			},
		},
		watch:{
			allData:{
				handler(val){
					var psgList =  val.changePsgList || [];
					this.tkChangePersonList = this.$common.deepCopy(psgList);
					var changeList = val.changeRangeList || [];
					this.tkChangeInfoList = this.$common.deepCopy(changeList);
					this.tripType = val.tripType;
					// 默认选择
					// 先默认航段选择
					this.tkChangeInfoList.map(rangeItem=>{
						if(rangeItem.sn>-1){
							this.getRangeCheckStatus(rangeItem)
						}
						
					})
					// 再根据选择的航班，乘机人可改签航段集合，
					this.tkChangePersonList.map(psgItem=>{
						this.getCheckStatus(psgItem)
					})
					// if(this.tkChangePersonList.length == 1){
					// 	this.getCheckStatus(this.tkChangePersonList[0])
					// }
					
					// if(this.tkChangeInfoList.length == 1){
					// 	let rangeItem = this.tkChangeInfoList[0];
					// 	if(!(rangeItem.refundFlag>0)){
							
					// 	}
						
					// }
					if(val.tripType == 1){
						this.getNeedApprove();
						if(val.ccsqdId && this.sqdGk == 1){
							this.getCcsqdDetail(val.ccsqdId,val.routeId)
						}
					}
				},
				immediate: true,
				deep:true
			},
		},
		methods:{
			getIsWei(){
				let weiFilterRangeList = this.tkChangeInfoList.map(item=>item).filter(rangeItem=>{
					return !!this.chooseRange[rangeItem.sn] 
				}).filter(rangeItem=>{
					let chooseFlight = rangeItem.chooseFlight || {};
					let chooseCabin = chooseFlight.chooseCabin || {};
					return !!chooseCabin.violateItem
				})
				console.log(weiFilterRangeList.length > 0)
				return weiFilterRangeList.length > 0
			},
			pickerOptions(minDay,maxDay){
					
				let minTime = this.$common.getStartDateTimeStamp(minDay);
				let maxTime = this.$common.getStartDateTimeStamp(maxDay);
				return {
						disabledDate(time) {
							return time.getTime() < minTime  || time.getTime() > maxTime;
						},
					}
			},
			// 选择出差申请单列表
			chooseCcsqd(){
				// 如果当前订单不可修改出差申请单，则不弹窗出差申请单选择按钮
				if(this.allData.showChangeTravelNo != 1){
					this.$message.error('当前订单，出差申请单不可重新选择！')
					return 
				}
				this.isShowChooseCcsqd = true;
			},
			
			// 选择出差申请单 回调
			chooseTravelApply(orderDetail){
				this.ccsqdDetail = orderDetail;
				this.isShowChooseCcsqd = false;
				// this.$emit('changeAllData',{ccsqdId:orderDetail.ccsqdId})	
			},
			// 获取出差单的详情
			getCcsqdDetail(ccsqdId,routeId){
				
				var queryString = {
					ccsqdId: ccsqdId
				};
				this.$conn.getConn('travelRequest.travelRequestInfo')(queryString,(res)=>{
					let data = res.data || {};
					this.ccsqdDetail = {
						...data,
						ccsqdId:data.id,
					};
					// 当有routeId时，
					if(routeId){
						this.ccsqdDetail.allRouteList = this.ccsqdDetail.allRouteList || [];
						let isCun = false;
						for(var r=0;r<this.ccsqdDetail.allRouteList.length;r++){
							let routeItemArr = this.ccsqdDetail.allRouteList[r] || [];
							for(var i=0;i<routeItemArr.length;i++){
								let routeItem = routeItemArr[i] || {};
								if(routeItem.routeId == routeId){
									this.ccsqdDetail.chooseRouteItem = routeItem;
									isCun = true;
									break;
								}
							}
							
							if(isCun){
								break;
							}
						}
						
					}
					
				},(err)=>{
					
				})
			},
			
			
			// 是否可以选择乘机人
			isCanCheck(psgItem,chooseRange){
				let bool = false;
				let canChangeList = psgItem.canChangeList || [];
				// 如果不存在可以改签的航段
				if(canChangeList.length == 0){bool = true};
				/*
				*   判断当前乘机人，在已选乘机人的可以选择的航段，是否在已选择的航段中都可以改签？
				*   如果都满足，该乘机人就可以进行选择
				*   如果有一个不满足，该乘机人就被禁用，不可以选择
				* 
				***/
				for(var rangeKey in chooseRange){
					
					// 判断每一个被选中的航程，该乘机人是否可以改签
					let filterChangeList = canChangeList.filter(changeItem=>{
						return changeItem.sn ==  rangeKey && changeItem.canChange == 1
					})
					// 如果不存在，则
					if(filterChangeList.length == 0){
						bool = true;
					}

				}
				
				
				return bool
			},
			// 是否可以选择航段
			isCanChooseRange(rangeItem,choosePsg){
				let bool = false;
				// 如果航段已经非正常航段
				// if(rangeItem.refundFlag>0){
				// 	bool = true;
				// }
				/*
				*  判断当前航段，在已选乘机人的每一个人中，是否都满足可以改签？
				*   如果都满足，该航段就可以进行选择
				*   如果有一个不满足，该航段就被禁用，不可以选择
				***/ 
				let isAll = true;
				for(var psgKey in choosePsg){
					let psgItem = choosePsg[psgKey] || {};
					let canChangeList = psgItem.canChangeList || [];
					// 判断每一个被选中的航程，该乘机人是否可以改签
					let filterChangeList = canChangeList.filter(changeItem=>{
						return changeItem.sn ==  rangeItem.sn && changeItem.canChange == 1
					})
					// 如果不存在，则
					if(filterChangeList.length == 0){
						isAll = false
					}
					

				}
				if(!isAll){
					bool = true
				}
				return bool
			},
			// 是否该航段已经选中,
			isChooseRange(sn,chooseRange){
				var bool = false;
				if(chooseRange && chooseRange[sn]){
					bool = true;
				}
				return bool
			},
			
			
			// 选中改签的航段
			getRangeCheckStatus(rangeItem,rangeIndex){
				let sn = rangeItem.sn;
				if(this.chooseRange[sn]){
					delete this.chooseRange[sn]
				}else{
					this.chooseRange[sn] = rangeItem;
				}
				this.$forceUpdate()
				
			},
			// 选中改签人
			getCheckStatus(psgItem){
				// 判断该乘机人中，已经选择的乘机人是否可以改签
				let canChangeList = psgItem.canChangeList  || [];
				let bool = true;
				for(var snKey in this.chooseRange){
					let filterCanChangeList = canChangeList.filter(canItem=>canItem.sn == snKey && canItem.canChange  == 1)
					if(filterCanChangeList.length == 0){
						 bool = false;
					}
				}
				// 如果，选中的航段中，该乘机人的可改签航段的集合对象中，任务一个航段不可改签，则该乘机人不默认选中
				if(!bool){
					return 
				}
				
				// 使用航班信息 添加上已选中 乘机人
				if(this.choosePsg[psgItem.psgId]){
					delete this.choosePsg[psgItem.psgId]
				}else{
					this.choosePsg[psgItem.psgId] = psgItem;
				}
				this.$forceUpdate()
		
				// 获取最低员工职级
				this.minEmpMap =  this.getMinEmpRank();
				
			},
			// 最低员工职级
			getMinEmpRank(){
				let arr = [];
				for(var psgKey in this.choosePsg){
					arr.push(this.choosePsg[psgKey])
				}
				
				let minEmpMap =  this.$common.getMinEmpRank(arr) || {};
				
				return minEmpMap
				
			},
			// 判断当前预订是否需要审批
			
			getNeedApprove() {
			  var query = this.$conn.getConn('commu.getUserTravelInfo');
			  query({}, res => {
			    var data = res.data || {};
			    var approvalBean = data.approvalBean || {};
			    var spkzMap = approvalBean.spkzMap || {};
			    spkzMap['11003'] = spkzMap['11003'] || {};
			    this.needApprove = spkzMap['11003'].sfkq == 1 || (spkzMap['11003'].sfkq == 2 && this.isShowWei);
			  }, err => {
				
			  })
			},
			
			// 机票查询
			queryTk(rangeIndex,rangeItem){
				//要改签的航段
				let sn = rangeItem.sn;
				if(!this.chooseRange[sn]){
					this.$alert('请先选择要改签的航段', '温馨提示', {
						type:'warning',
					    confirmButtonText: '确定',
					});
					return 
				}
				
				
				// 申请改签要先选择改签的乘机人
				
				if(Object.keys(this.choosePsg).length === 0){
					this.$alert('请先选择要改签的乘机人', '温馨提示', {
						type:'warning',
					    confirmButtonText: '确定',
					});
					return 
				}
				// 出发日期
				if(!rangeItem.newDepDate){
					this.$alert('请选择出发日期', '温馨提示', {
						type:'warning',
					    confirmButtonText: '确定',
					});
					
					return
				}
				
				
				/*
					暂无升舱
				*/
			   this.currentTkInfo = rangeItem;
			   this.currentRangIndex = rangeIndex;
				
				this.searchTk();
				this.searchVisible = true;
			},
			// 搜索航班信息
			searchTk(){
				
				this.loadingFlightList = 1;
				// let queryString = {
				// 		routeId: this.allData.routeId,
				// 		alterType: 1, // 改签类别：1改期，2升舱 必填 , 
				// 		arriveAirport: this.currentTkInfo.arriveAirport,
				// 		changeType: this.changeFlag,// 改签类型：1正常单改签， 2改签单改签 ,
				// 		departAirport: this.currentTkInfo.departAirport,
				// 		departDate: this.currentTkInfo.newDepDate,
				// 		orderId: this.allData.orderId,
				// 		rangeId: this.currentTkInfo.rangeId,	
				// };
				let queryString = {
				  "alterType": 1, // 改签类别：1改期，2升舱 必填 , 
				  "changeId": this.changeFlag == 2?this.allData.changeId:"",
				  "departDate": this.currentTkInfo.newDepDate,
				  "orderId": this.changeFlag == 1?this.allData.orderId:"",
				  // "rangeId": "string",
				  "sn": this.currentTkInfo.sn,
				}
				
				this.$conn.getConn('tkQuery2023.tkChangeQueryFlight2')(queryString,(res)=>{
					let data = res.data || {};
					data.flightMap = data.flightMap || data.flightList || {};
					if(this.allData.tripType == 1 &&  data.cacheId){
						this.toMatchClbz(data)
					}else{
						this.handleFlightListData(data)
					}
					
					
				},(err)=>{
					this.loadingFlightList = 4;
				})
			},
			// 匹配差旅标准
			toMatchClbz(data){
				var queryString = {
				  "cacheId": data.cacheId,
				  "changeFlag": this.changeFlag,
				  // "corpId": "string",
				  "minZj": this.minEmpMap.empRank,
				  "routeId": this.allData.routeId
				}

				
				if(!(queryString.minZj && queryString.minZj > 0)){
					this.handleFlightListData(data )
				}else{
					var queryUrl = 'tkQuery2023.matchClbz'
					this.$conn.getConn(queryUrl)(queryString,(res)=>{
						let mData = res.data || {};
						
						for(var mKey in mData){
							data['priceMap'][mKey] = Object.assign(data['priceMap'][mKey],mData[mKey])
						}
						this.handleFlightListData(data)
						
					},(err)=>{
						this.handleFlightListData(data)
					})
				}
				
				
			},
			// 存储、并处理航班数据
			handleFlightListData(data){
				
				let flightList = this.$common.objToArr(data.flightList || data.flightMap || {});
				// 处理航班列表，重新排序格式
				let newFlightList = tkCommonJs.chuLiFlightCabinPriceArr(flightList, data.priceMap,data.planeMap,data.flightMap,{});
				
				this.flightList = newFlightList;
				this.flightMap = data.flightMap || {};
				this.cacheId = data.cacheId;
				if(this.flightList.length>0){ // 查询有结果
					this.loadingFlightList = 2;
				}else{ // 查询无结果
					this.loadingFlightList =3;
				}
				
				
			},
			// 选择某一航班
			toChooseFlight(flightItem){
				let newFlightItem = this.$common.deepCopy(flightItem);
				newFlightItem['cacheId'] = this.cacheId;
				this.chooseFlight = newFlightItem;
				
				let bookType = newFlightItem.chooseCabin.bookType;
				//bookType   1. 正常预订 不违背  2. 违背 预订提醒  3.抢票  4.不可预订
				if(bookType == 1){
					this.chooseOneFlightAndCabin();
				}else if(bookType == 2){
					this.isShowWeiBei = true;
					this.violateItem = this.chooseFlight.chooseCabin.violateItem;
				}else if(bookType == 3){
					console.error('该舱位暂无余票')
				}else if(bookType == 4){
					console.error('该舱位不可预订')
				}
				
				
				
			},
			// 选中原因
			chooseReason(obj){
				
				this.chooseFlight.chooseCabin['reasonCode'] = obj.reasonCode ;
				this.chooseFlight.chooseCabin['reasonDesc'] = obj.reasonDesc;
				this.isShowWeiBei = false;
				
				this.chooseOneFlightAndCabin();
			},
			// 取消原因
			cancleReason(){
				this.isShowWeiBei = false;
			},
			// 赋值一个航班
			chooseOneFlightAndCabin(){
				this.tkChangeInfoList[this.currentRangIndex]['chooseFlight'] = this.chooseFlight;
				this.tkChangeInfoList[this.currentRangIndex]['chooseFlight']['cacheId'] = this.chooseFlight.cacheId;
				this.searchVisible = false;
				console.log(this.tkChangeInfoList)
				
				this.isShowWei = this.getIsWei();
				// 获取是否开启审批
				this.getNeedApprove();
				// 获取审批规则
				this.getSpgzList();
			},
			
		
			// 关闭搜索
			closeSearch(){
				this.searchVisible = false;
			},
			
			
			// 审批规则处理
			// 审批规则模块
			getSpgzList(){
				let isWei = false;
				this.tkChangeInfoList.forEach((rangeItem,rangeIndex)=>{
					
					var sn = rangeItem.sn;
					let chooseFlight = rangeItem.chooseFlight || {};
					if(this.chooseRange[sn] && chooseFlight.chooseCabin && chooseFlight.chooseCabin.violateItem ){
						isWei = true;
					}
				})
				
				// 获取改签人的员工id
				let psgArr = [];
				 for(var psgKey in this.choosePsg){
					 let psgItem = this.choosePsg[psgKey];
					 
					 if(psgItem['empId']){
						 psgArr.push(psgItem['empId'])
					 }
				 }
				 this.loadingSpgzList = true;
				 let bookStyle = this.$common.getBookStyle(this.$store,10901);
				// 出差申请单模式
				if(bookStyle == 2){
					this.getTravelApprovalRules(isWei,psgArr);
				}else{ // 单订模式
					this.getSingleApprovalRules(isWei,psgArr);
				}
			},
			
			
			// 出差申请单模式下匹配审批规则
			getTravelApprovalRules(isWei,psgArr){
			
				let objParams = {};
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "fcny":  0,
				  "ifViolate": isWei?1:0,
				  "orderType": 11003, // 单据类型
				  "projectId": '',
				  "routeId": this.allData.routeId,
				  "travelEmpIds": psgArr.join(','),
				}
				this.$conn.getConn('commu.travelRouteMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
				},(err)=>{
					this.spgzErrorAjax(err)
				})
			},
			// 单订模式下批配审批规则
			getSingleApprovalRules(isWei,psgArr){
				let objParams = {};
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "ifViolate": isWei?1:0,
				  "orderType": 11003, // 单据类型
				  "projectId": '',
				  "travelEmpIds":  psgArr.join(','),
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
				},(err)=>{
					this.spgzErrorAjax(err)
				})
			},
			// 审批规则成功回调
			spgzSuccessAjax(res){
				this.spgzList = res.data || [];
				if(this.spgzList.length == 0 ){
					this.isWuXuShenPi = true;
				}else{
					// 如果是只有一个审批规则，则直接默认
					if(this.spgzList.length == 1){
						this.getCurrentSpgz(this.spgzList[0]);
					}
					this.isWuXuShenPi = false;
				}
				this.loadingSpgzList = false;
			},
			//  审批规则失败回调
			spgzErrorAjax(err){
				this.spgzList = [];
				this.isWuXuShenPi = false;
				this.$alert(err.tips,'温馨提示',{
					type:'error'
				})
				this.loadingSpgzList = false;
			},
			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzId || spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
			},
			
			
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
	    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
	    border-radius: 5px;
	}
	.tch-main{
		font-family: '微软雅黑';
		color: #223344;
		.padL10{
			padding-left: 10px;
		}
		.el-card /deep/ .el-card__body{
			padding: 0;
		}
		.marT20{
			margin-top: 20px;
		}
		.tch-top{
			display: flex;
			height: 80px;
			text-align: left;
			background-color: #006BB9;
			padding:0 30px; 
			.jp-title{
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 80px;
			}
		}
		.tch-con{
			padding: 30px;
			.tch-wf{
				text-align: left;
				line-height: 30px;
				font-weight: 600;
			}
			.tch-con-t{
				height: 80px;
				display: flex;
				justify-content: space-between;
				background-color: #F3F5F8 ;
				padding: 20px 0;
				box-sizing: border-box;
				margin-bottom: 15px;
				.tch-l0 {
					line-height: 40px;
					width: 45px;
					// border-right: 1px solid #D1DBE7;
					font-size: 14px;
					font-weight: bold;
					
				}
				.tch-l1 {
					// line-height: 40px;
					width: 165px;
					border-right: 1px solid #D1DBE7;
					font-size: 14px;
					font-weight: bold;
					
				}
				.tch-l2{
					width:460px;
					padding: 0 20px;
					border-right: 1px solid #D1DBE7;
					.tch-l2-hbt{
						display: flex;
						height: 18px;
						line-height: 16px;
						.tch-l2-hbl,.tch-l2-hbr{
							font-weight: bold;
							font-size: 18px;
							width: 133px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.tch-l2-hbc{
							width: 154px;
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;
						}
					}
					.tch-l2-hbf{
						display: flex;
						height: 18px;
						line-height: 16px;
						.tch-l2-hbfl,.tch-l2-hbfr{
							width: 133px;
							font-size: 12px;
							font-weight: 400;
							line-height: 15px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							display: flex;
							align-items: flex-end;
							justify-content: center;
						}
						.tch-l2-hbfc{
							width: 154px;
							background-color: #D1DBE7;
							height: 1px;
							margin-top: 7px;
						}
					}
				}
				
				.tch-l3{
					width: 194px;
					display: flex;
					justify-content: center;
					line-height: 40px;
					border-right: 1px solid #D1DBE7;
					.tch-l3-pad{
						padding-left:10px;
					}
				}
				
				.tch-l4{
					width: 144px;
					line-height: 40px;
					border-right: 1px solid #D1DBE7;
				}
				.tch-l5{
					width: 150px;
					font-size: 14px;
					line-height: 40px;
				}
			}
			
			
		
			.searchDate{
				display: flex;
				/deep/ .el-input__inner {
					padding-left: 30px;
					height: 45px;
					width: 225px;
					line-height: 45px;
					background: #F9FBFF;
					border: 2px solid #D9E1ED;
					border-radius: 1px;
				}
			}
			.queryBtn {
				border-radius: 0;
				height: 45px;
				width: 150px;
				font-size: 15px;
				font-weight: 600;
				margin-left: 20px;
			}
		}
		/deep/ .el-dialog{
			width: 500px;
			text-align: left;
		}
		.tch-search{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.search-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1200px;
				padding: 20px;
				.searchTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
				}
				.iconClose{
					font-size: 24px;
					cursor: pointer;
				}
				.iconClose:hover{
					color:#00A7FA;
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 15px;
						color: #00A7FA;
						// color: #FFFFFF;
						// background-color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
				.list-main{
					max-height: 300px;
					min-height: 500px;
					overflow-y: auto;
					text-align: left;
					.list-con{
						display: flex;
						min-height: 40px;
						margin-bottom: 3px;
						background-color: #eeeeee;	
						cursor: pointer;
						.img{
							width: 25px;
							height: 25px;
						}
					}
					.list-con:hover{
						background-color: #dddddd;	
						box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
						
					}
				}
			}
			
		}
		.dmain{
			.dia-gz{
				display: flex;
				margin-bottom: 15px;
				
				border: 1px solid #DBDBDB;
				.gz-l{
					width: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #EFEFEF;
				}
				.gz-r{
					width: 380px;
					padding:15px;
					line-height: 20px;
				}
			}
		}
		.marT30{
			margin-top: 30px;
		}
	}
	.approval-rule{
		margin-bottom: 30px;
		text-align: left;
		padding-left: 30px;
	}
	.approval-rule-title{
		margin: 30px 0 15px 0;
	}
	.setSelect{
		/deep/ .el-input__inner{
			width: 675px;
			height: 45px;
			background: #F4F9FF;
			border: 3px solid #006BB9;
			border-radius: 1px;
		}
	}
	// 乘机人
	.tch-fl-main{
		height: 45px;
		background-color: #F3F5F8;
		padding: 12.5px 0;
		margin: 0 30px ;
		margin-top: 10px;
		
		display: flex;
		.tch-fl1{
			width: 45px;
			height: 20px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl2{
			width: 274px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl3{
			width: 209px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl4{
			width: 84px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl5{
			width: 174px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl6{
			width: 179px;
			border-right: 1px solid #D1DBE7;
		}
		.tch-fl7{
			width: 169px;
		}
	}
	
</style>
