import { render, staticRenderFns } from "./TrainChange.vue?vue&type=template&id=c764353e&scoped=true&"
import script from "./TrainChange.vue?vue&type=script&lang=js&"
export * from "./TrainChange.vue?vue&type=script&lang=js&"
import style0 from "./TrainChange.vue?vue&type=style&index=0&id=c764353e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c764353e",
  null
  
)

export default component.exports