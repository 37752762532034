<template>
	<div class="tc-main" :style="{'min-height':height+'px'}">
		<yj-nav-detail></yj-nav-detail>	
		<div class="main">
			<tk-change-person 
				ref="tkRangePerson" 
				:allData="tkChangeData"
			></tk-change-person>
			
			<yj-contact-info :linkInfo="linkObj"></yj-contact-info>
			
			<yj-reason 
				ref="tk-reason"
				:orderType="11003" 
				:params="134" 
				:showTGtext="true" 
				:text="text" 
				:marginTop="'30px'"
				@refundChangeReason="getChangeReason"
			></yj-reason>
			
			<div style="margin-top: 45px;padding-bottom: 50px;">
				<el-button class="w240 h64 font16" @click="cancel()">取消</el-button>
				<el-button class="w240 h64 font16" type="primary" @click="applyChange()">提交改签</el-button>
				<el-button class="w240 h64 font16" type="primary" @click="applyChange(1)">提交改签并出票</el-button>
			</div>
		</div>
		
		
		<!-- 确认改签 -->
		<el-dialog
			title="确认改签"
			:visible.sync="showChangeFeeDetail"
			>
			<div class="dmain">
				
				<div class="box-card" style="font-size: 14px;">
				  <div  class="text-item">
					  <span class="left">乘机人</span>
				      <span class="right">共{{psgList.length}}人</span>
				    </div>
				  <div class="text-item">
				    <span class="left">原销售价</span>
				    <span class="right">¥{{changeFee.oldSalePrice}}/人</span>
				  </div>
				  <div class="text-item">
				    <span class="left">新销售价</span>
				    <span class="right">¥{{changeFee.newSalePrice}}/人</span>
				  </div>
				  <div class="text-item">
				    <span class="left">票价差价</span>
				    <span class="right">¥{{changeFee.priceDiff}}/人</span>
				  </div>
				  <div class="text-item">
				    <span class="left">改签手续费</span>
				    <span class="right">¥{{changeFee.changePoundage}}/人</span>
				  </div>
				  <div class="text-item">
				    <span class="left">改签服务费</span>
				    <span class="right">¥{{changeFee.serviceFee}}/人</span>
				  </div>
				  <div class="text-item">
				    <span class="left">改签总费用</span>
				    <span class="right">¥{{changeFee.changeAmount}}/人</span>
				  </div>
		
				  
				</div>
			

			</div>
			<span slot="footer" class="dialog-footer">
			  <span class="left">改签费用总计：<span style="font-size: 20px;color: #409EFF;">¥{{changeAmountTotal}}</span></span>
			  <el-button @click="cancelAlter()">取 消</el-button>
			  <el-button type="primary" @click="confirmAlter()">确定改签</el-button>
			</span>
		</el-dialog>
		
		
	</div>
</template>

<script>
	import TkChangePerson from "./tkComponent/tkRefundChange/TkChangePerson";
	export default {
		name: 'TkChange',
		data() {
			return {
				tkChangeData: {},
				text: '非自愿改签！由于航班取消、提前、延误、航程改变或其它突发状况，造成您不能乘机，您可以申请改签。',
				reasonObj: {}, // 原因对象
				
				appId:"",
				
				showChangeFeeDetail:false,
				changeFee:{
					changeAmount:0,// (number, optional): 改签总费用差价+改签手续费+改签服务费 ,
					changePoundage :0,// (number, optional): 改签手续费 ,
					newSalePrice :0,// (number, optional): 新销售价 ,
					oldSalePrice :0,// (number, optional): 原销售价 ,
					pnrNo :'',// (string, optional): 新编码，下单时传 ,
					priceDiff:0,//  (number, optional): 票价差价 ,
					serviceFee :0,// (number, optional): 改签服务费
				},
				
				loadingTest:null,
				psgList:[], // 选择的乘机人
				linkObj:{},

				autoPay:'',// 是否出票，   1 代表  提交出票
			}
		},
		components: {
			'tk-change-person': TkChangePerson,
		},
		computed: {
			query() {
				return this.$route.query || {};
			},
			orderId() {
				return this.query.orderId;
			},
			orderType() {
				return this.query.orderType;
			},
			changeFlag() {
				return this.query.changeFlag == 2?2:1;
			},
			height() {
				let height = document.body.clientHeight;
				return height
			},
			changeAmountTotal() {
			  var price = this.changeFee.changeAmount || 0
			  return this.psgList.length * price
			},
			userInfo(){
				return this.$common &&this.$common.getUserInfo();
			},

			//显示提交订单按钮
			showTiJiaoBtn() {
				let bool = true;
				// 显示【提交出票】按钮时，是否能隐藏【提交按钮】
        		let isHiddenTjBtn = this.$common.getVipParamterByParNo(this.$store,20019,0,'value2') ;
				if(isHiddenTjBtn == 1 && this.showTiJiaoAndChuBtn){
					bool = false
				}
				return bool;
          		// return true
			},
			// 显示提交并出票按钮
			showTiJiaoAndChuBtn() {
				var bool = false;
        		let isAutoPayCanShu = this.$common.getVipParamterByParNo(this.$store,20019,0,'value1') ;

				let isWuXuShenPi = this.$refs['tkRangePerson'] && this.$refs['tkRangePerson']['isWuXuShenPi'];

				// 1. 开启自动出票参数 1   2.无需审批     3.因公模式    4.公司支付模式
				if (isAutoPayCanShu == 1 && isWuXuShenPi && this.tkChangeData.tripType == 1 && this.userInfo.empInfo.useAccount) {
					bool = true;
				} else {
					bool = false;
				}
				return bool;
        		// return true
			},
			// psgList(){
			//   return this.getPsgList();
			// }
		},
		mounted() {
			this.getTkChange();
			
			this.getLinker();
		},
		methods: {
			getLinker(){
	
				let empInfo = this.userInfo.empInfo || {};
				
				this.linkObj = {
					linkman: empInfo.empName,
					linkMobile: empInfo.phoneNumber,
					linkTel: empInfo.phoneNumber,
				}	
				
			},
			// 获取可改签的乘机人
			getTkChange() {
				let params = {
					orderId: this.orderId,
					changeFlag: this.changeFlag
				}
				// "change.applyChange"
				let queryUrl = "tkQuery2023.tkChangeApply4";
				
				this.$conn.getConn(queryUrl)(params, (res) => {
					let data = res.data || {};
					this.tkChangeData = data;
					
					// showChangeTravelNo :1  是否可以修改出差单
				}, (err) => {

				})
			},
			// 取消并关闭
			cancel() {
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					window.close();
				}).catch(() => {
					 
				});
			},
			// 提交改签订单
			applyChange(autoPay) {
				this.autoPay = autoPay;
	
				var isWuXuShenPi = this.$refs['tkRangePerson']['isWuXuShenPi'];
				let appId = this.$refs['tkRangePerson']['chooseSpgz']?this.$refs['tkRangePerson']['chooseSpgz']['gzId']:'';
				// 获取航段信息
				let rangeList =  this.getRangeList();
				// 获取乘机人信息
				let psgList = this.getPsgList();
				this.psgList = psgList;
				// 参数校验
				// 未选择改签的乘机人
				if(psgList.length == 0){
					this.$alert('请选择要改签的乘机人！', '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
				// 未选择改签的航段
				if(rangeList.length == 0){
					this.$alert('请选择要改签的航段！', '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}else{
					// 验证选中的改签航班，是否选择新的航班
					let filterNoChooseFlightList =  rangeList.filter(rangeItem=>{
						let chooseFlight = rangeItem.chooseFlight || {}
						return !chooseFlight.flightNo
					})
					if(filterNoChooseFlightList.length>0){
						this.$alert('请选择新的改签航班！', '温馨提示', {
							type:'error',
						    confirmButtonText: '确定',
						});
						return false
					}
				}
				
				
				// gaiQianReasonGk
				// imageUrl
				// isVoluntarily
				// reasonId
				// reasonName
				// changeReasonVip
				if(this.reasonObj.isVoluntarily == 1 && !this.reasonObj.reasonName){
					let tips = "自愿改签，请选择改签原因！"
					this.$alert(tips, '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
				// 非自愿改签，请选择改签原因！
				if(this.reasonObj.isVoluntarily == 1 && !this.reasonObj.changeReasonVip && this.reasonObj.gaiQianReasonGk == 1){
					let tips = "根据公司差旅政策规定，改签单必须填写改签原因，才可提交改签"
					this.$alert(tips, '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
				if(this.reasonObj.isVoluntarily == 2 && !this.reasonObj.reasonName){
					this.$alert('非自愿改签，请选择改签原因！', '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
				// 非自愿改签，添加上传图片验证
				if(this.reasonObj.isVoluntarily == 2 && !this.reasonObj.imageUrl){
					this.$alert('非自愿改签，请上传改签证明！', '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
			
				
				// 审批规则，如果需要审批，但是没有审批规则
				if(this.tkChangeData.tripType == 1 && this.$refs['tkRangePerson'].isNeedApproval && !isWuXuShenPi && !appId){
					this.$alert('请选择审批规则！', '温馨提示', {
						type:'error',
					    confirmButtonText: '确定',
					});
					return false
				}
				
				this.$confirm(`1.提交改签前请先确认,是否已经取消值机,取消值机后,才可以改签!</br>2.改签可能产生改签手续费，请仔细查看退改签规则!</br>是否继续提交？`, '温馨提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning',
					dangerouslyUseHTMLString:true,
				}).then(() => {
				    this.addChangeAjax()
				}).catch(() => {
				           
				})	
			},

			
			/**
			 *   提交改签
			 *     1. 获取改签政策，创建pnrNo
			 *     2. 提交改签申请订单创建
			 * 
			 * **/ 
			addChangeAjax(){
				this.tiJiaoLoading = true;
				// 开启遮罩保护
				this.loadingTest =	this.openLoading();
				
				// // test - demo	start	
				// this.showChangeFeeDetail = true;
				// return
				// // test - demo	end
				
				  // 判断是否有官网产品，如果是则直接提交改签单，tsms端票务员，手动计算获取费用
				let isOp = this.getGwRangeList();
				// 如果存在官网价，则直接提交改签
				if(isOp){

					this.createGaiOrderAjax({})
				}else{
					// 计算改签费用和生成pnr
					this.getPreChangeFee();
				}
				
				
				
			},

			// 判断是否存在官网价产品
			getGwRangeList(){
				let bool = this.tkChangeData.tkType == "OP" || this.tkChangeData.tkType == "NDC";
			
				
				return bool

			},

			/*
			 *  获取改签政策
			 *  入参：传入要改签的乘机人集合 psgObj
			 *  @description  提交ajax请求
			 */
			getPreChangeFee() {
			    // 获取改签的人
			    var psgList = this.getPsgList({},1);
			    // 获取改签航段
			    var rangeList = this.getRangeList({},1);
			    let queryObj = {
				  "changeType": this.changeFlag, // 改签类型 1正常改签 2改签后改签 ,
				  "orderId": this.orderId,
				  "psgList": psgList,
				  "rangeList": rangeList,
				  "tripType": this.tkChangeData.tripType, // 因公因私1因公 2因私
				}
				let queryUrl = "tkQuery2023.preChange";
				// POST /1.0.0/et/preChange 改签费用计算并创建编码
			    this.$conn.getConn(queryUrl)(queryObj, (resp) => {
				  
				  let changeFeeObj = resp.data || {};
				  
				  this.changeFee = changeFeeObj;
				  // 标记可以申请状态
				  this.createOrderIng = false;
				  // 如果改签差价为0，直接提交改签申请，否则弹窗提示用户【确认改签费用】
				  if (!this.changeFee.changeAmount || this.changeFee.changeAmount == 0) {
				    this.createGaiOrderAjax(this.changeFee)
				  } else { // 显示改签费用弹窗
				    this.showChangeFeeDetail = true;
					
					this.loadingTest.close();
					
				  }
			  }, (err) => {
				  this.tiJiaoLoading = false;
				  this.loadingTest.close();
				  
			      this.$alert(err.tips,'改签失败',{
			    	type:'error',
			    	confirmButtonText: '确定',
			    	callback:()=>{
			    		
			    	}
			    })
				
			  })
			},
			// 取消改签
			cancelAlter(){
				this.showChangeFeeDetail = false;
				this.loadingTest.close();
			},
			// 确认改签政策，并提交订单
			confirmAlter(){
				this.createGaiOrderAjax(this.changeFee)
			},
			// 提交改签订单
			createGaiOrderAjax(changeFee){
				// let appId = this.$refs['tkRangePerson']['chooseSpgz']?this.$refs['tkRangePerson']['chooseSpgz']['gzId']:'';
				let ccsqdDetail = this.$refs['tkRangePerson']['ccsqdDetail'] || {};
				let  psgList = this.getPsgList(changeFee,2);
				let rangeList = this.getRangeList(changeFee,2);
				let queryObjForCreateOrder = {
				   autoPay:this.autoPay,
				  "alterType": 1,//  改签类别 1改期 2升舱 必填 ,
				  "appId": this.$refs['tkRangePerson']['chooseSpgz']?this.$refs['tkRangePerson']['chooseSpgz']['gzId']:'',
				  // "applyUser": "string",
				  // "bzbz": "string",
				  "ccsqdId": ccsqdDetail.ccsqdId || this.tkChangeData.ccsqdId,
				  "changeFlag": this.changeFlag,//  改签类型 1正常单改签 2改签单改签 ,
				  "changeOrderId": this.tkChangeData.changeOrderId,
				  "changeProveUrl": this.reasonObj.imageUrl,
				  "changeReason":  this.reasonObj.reasonName,
				  "changeReasonCode": this.reasonObj.reasonId,
				  "changeReasonCodeVip": this.reasonObj.reasonId,
				  "changeReasonVip": this.reasonObj.changeReasonVip,
				  "changeType": this.reasonObj.isVoluntarily || 1, // 改签类型1自愿；2非自愿 ,
				  // "control": "string",
				  "ignoreRepeat": false,
				  // "jsBz": "string",
				  "linkEmail": this.linkObj.linkEmail,
				  "linkTel": this.linkObj.linkTel,
				  "linkman": this.linkObj.linkman,
				  // "nBigPnrNo": "string",
				  "nPnrNo": this.changeFee.pnrNo,
				  // "oBigPnrNo": "string",
				  // "oPnrNo": "string",
				  "orderId": this.tkChangeData.orderId,
				  "passengers":psgList,
				 
				  // "productType": "string",
				  "ranges":rangeList,
				  "refundBxList": [
				    // {
				    //   "bxId": "string"
				    // }
				  ],
				  "routeId": this.tkChangeData.routeId,
				}
				let queryUrl = "tkQuery2023.tkChangeOrderAdd2";
				this.$conn.getConn(queryUrl)(queryObjForCreateOrder, (res) => {
					this.loadingTest.close();
					this.$alert('已提交改签，请到全部订单查看订单','温馨提醒',{
						type:"warning",
						confirmButtonText: '确定',
						callback:()=>{
							this.$router.push({
								path:'/yjTravel/myTravel/userCenter/order',
								query:{toBePaid:3}
							})
						}
					})
				},err=>{
					this.tiJiaoLoading = false;
					this.loadingTest.close();
					
					this.$alert(err.tips,'改签失败',{
						type:'error',
						confirmButtonText: '确定',
						callback:()=>{
							
						}
					})
				})
			},
			
			/**
			 * 	 
			 *   @description  获取航段列表
			 *   @param {Object} changeFee = {}
			 * 	 @param {Number | String} type = 1   1:获取改签费用，2:提交订单  3. 获取新航段
			 *   @return {Array}
			 * **/ 
			getRangeList(changeFee,type){
				changeFee = changeFee || {};
				var tkChangeInfoList = this.$refs['tkRangePerson']['tkChangeInfoList'] || [];
				var chooseRange = this.$refs['tkRangePerson']['chooseRange'] || {};
				var rangeList = tkChangeInfoList.filter((rangeItem,rangeIndex)=>{
						return chooseRange[rangeItem['sn']]
				}).map(rangeItem=>{
					let chooseFlight = rangeItem.chooseFlight || {};
					let chooseCabin = chooseFlight.chooseCabin || {};
					let newObjItem = {};
					if(type == 1){
						newObjItem = {
						  "arriveAirport": chooseFlight.arriveAirport,
						  "arriveTime": chooseFlight.arriveDate + ' ' + chooseFlight.arriveTime,
						  "cabinCode": chooseCabin.cabin,
						  "departAirport": chooseFlight.departAirport,
						  "departTime": chooseFlight.departDate + ' ' + chooseFlight.departTime,
						  "flightNo": chooseFlight.flightNo,
						  "shareFlightNo": chooseFlight.shareFlightNo,
						  "sn": rangeItem.sn,
						  plane:chooseFlight.plane,
						}
					}else if(type == 2){
						newObjItem =  {
						  "cacheId": chooseFlight.cacheId,
						  "nArriveAirport": chooseFlight.arriveAirport,
						  "nArriveDate": chooseFlight.arriveDate,
						  "nArriveTerminal": chooseFlight.arriveTerminal,
						  "nArriveTime": chooseFlight.arriveTime,
						  "nCabin": chooseCabin.cabin,
						  "nCabinName":  chooseCabin.cabinName,
						  "nChangeRule": chooseCabin.changeRule,
						  "nDepartAirport": chooseFlight.departAirport,
						  "nDepartDate": chooseFlight.departDate,
						  "nDepartTerminal": chooseFlight.departTerminal,
						  "nDepartTime": chooseFlight.departTime,
						  "nFlightNo": chooseFlight.flightNo,
						  "nModel":chooseFlight.model || chooseFlight.plane,
						  "nRefundeRule":chooseCabin.refundeRule,
						  "nShareFlightNo":chooseFlight.shareFlightNo,
						  "nUpgradeRule": chooseCabin.upgradeRule,
						  "priceId":chooseCabin.priceId,
						  "rangeId":rangeItem.rangeId,
						  "sn": rangeItem.sn,
						}
					}else if(type == 3){
						newObjItem = {
							...chooseFlight,
							...chooseCabin
						};
					}else{
						newObjItem = {...rangeItem};
					}
					
					return  newObjItem
					
				})
		
				return rangeList
				
			},
			/**
			 * 	 
			 *   @description  获取改签人列表
			 *   @param {Object} changeFee = {}
			 * 	 @param {Number | String} type = 1   1:获取改签费用，2:提交订单   
			 *   @return {Array}
			 * **/ 
			getPsgList(changeFee,type){
				var tkPsgList = this.$refs['tkRangePerson']?.['tkChangePersonList'] || [];
				var choosePsg = this.$refs['tkRangePerson']?.['choosePsg'] || {};
				
				let psgList = tkPsgList.filter((psgItem,psgIndex)=>{
						return choosePsg[psgItem['psgId']]
				}).map(psgItem=>{
					let newObjItem = {};
					if(type == 1){
						newObjItem =  {
							psgId:psgItem.psgId,
							tkno:psgItem.tkno
						  }
					}else if(type == 2){
						newObjItem =  {
							"cuChangeAmount": changeFee.changePoundage || 0, // 手续费
							"fcnyDiff": changeFee.priceDiff || 0,
							"nFcny": changeFee.newSalePrice || 0,
							"oFcny": changeFee.oldSalePrice || 0,
							"psgId": psgItem.psgId,
							"purchaseChangeAmount": changeFee.changePoundage || 0, // 手续费
							"seat": psgItem.seat, 
							"serviceFee": changeFee.serviceFee || 0,
						  }
					}else{
						newObjItem = {...psgItem};
					}
					
					return newObjItem
				})
				return psgList
			},
			//  获取新的票价
			getNewSalePrice(tkChangeInfoList){
				
				let salePriceTotal = 0;
				for(var k in tkChangeInfoList){
					let rangeItem =tkChangeInfoList[k] || {};
					let chooseCabin = rangeItem['chooseFlight']?(rangeItem['chooseFlight']['chooseCabin'] || {}):{};
					let salePrice = chooseCabin['salePrice'] || 0;
					 
					salePriceTotal  = this.$common.addition([salePriceTotal,salePrice]);
				}

				
				return salePriceTotal
			},

			// 改签原因，自愿改签原因，非自愿改签原因
			getChangeReason(reasonObj) {
				this.reasonObj = reasonObj || {};
			},
		},

	}
</script>

<style lang="scss" scoped>
	.tc-main {
		background-color: #EEF1F6;

		.main {
			margin: 0 auto;
			width: 1200px;
			padding-top: 40px;

			.box-card {
				margin-top: 28px;
			}
			
			.w240 {
				width: 240px;
			}

			.h64 {
				height: 64px;
			}

			.font16 {
				font-size: 16px;
			}
		}
	}
	.left{
		float: left;
	}
	.right{
		float:right;
	}
	.text-item{
		clear: both;
		padding: 10px 0;
		text-align: left;
		font-size: 14px;
	}
	
</style>
